import React, { useEffect, useMemo, useState, useImperativeHandle } from 'react';
import { css } from 'coral-system';
import { Box } from '@music163/foundation';
import axios from 'axios';
import { Button, Form, Input, Table } from 'antd';
import { getPath } from '../../utils/request-helper';
import { formatSearchResult } from '../../utils/search-reault-type-helper';
// import '../../stores';

const contentStyle = css`
  margin-top: 8px;
  display: flex;
  .left-table {
    flex: 1;
    overflow: hidden;
    margin-right: 8px;
  }
  .right-table {
    width: 288px;

    .right-table-row-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      max-width: 250px;
      > div {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

const DEFAULT_PAGING = {
  pageSize: 20,
  total: 0,
  current: 1,
};

// eslint-disable-next-line react/display-name
export const DataSelect = React.forwardRef(({ portalId, defaultValue }: { portalId: number, defaultValue: any[] }, ref) => {
  const [sourceData, setSourceData] = useState([]);
  const [selectedRows, setSelectedRows] = useState<any[]>(defaultValue);
  const [loading, setLoading] = useState(false);
  const [searchFormValues, setSearchFormValues] = useState({});

  useImperativeHandle(ref, () => {
    return {
      selectedRows,
    };
  });

  const [pagination, setPagination] = useState(DEFAULT_PAGING);
  const search = (data: any, params: any) => {
    if (!data) {
      data = {};
    }
    setLoading(true);
    axios
      .post(getPath('/external/portals/search'), data, {
        params,
      })
      .then((response) => {

        setSourceData(formatSearchResult(response.data.data.portalEsDataDtos));
        setPagination(getPagingFromRequest(response.data.paging));
        setSearchFormValues(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    search(null, {
      portalId,
      pageNum: 1,
      pageSize: 20,
    });
  }, []);

  const selectedRowKeys = useMemo(() => {
    return selectedRows.map((i) => i.key);
  }, [selectedRows]);

  const onSelectChange = (record: any, selected: any) => {
    if (selected) {
      setSelectedRows((prev) => [...prev, record]);
    } else {
      setSelectedRows((prev) => prev.filter((i) => i.key !== record.key));
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectChange,
  };
  return (
    <div>
      <Form
        layout="inline"
        onFinish={(values) => {
          search(values, {
            portalId,
            pageNum: 1,
            pageSize: DEFAULT_PAGING.pageSize,
          });
        }}
      >
        <Form.Item label="标题" name="keyword">
          <Input placeholder="请输入标题" />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType='submit'>
            搜索
          </Button>
        </Form.Item>
      </Form>
      <Box css={contentStyle}>
        <Table
          columns={[
            {
              dataIndex: 'name',
              title: '文档标题',
            },
          ]}
          dataSource={sourceData}
          pagination={{
            ...pagination,
            showSizeChanger: false,
            onChange(page) {
              search(searchFormValues, {
                portalId,
                pageNum: page,
                pageSize: DEFAULT_PAGING.pageSize,
              });
            },
          }}
          rowSelection={rowSelection}
          loading={loading}
          scroll={{ y: 500 }}
          className="left-table"
          bordered
        />
        <Table
          columns={[
            {
              dataIndex: 'name',
              title: `已选中文档${selectedRows.length ? ` (${selectedRows.length}个)` : ''}`,
              render: (_, record) => (
                <div className="right-table-row-item">
                  <div>{record.name}</div>
                  <Button
                    onClick={() => {
                      onSelectChange(record, false);
                    }}
                    icon={<DelIcon />}
                    type="text"
                    size="small"
                  />
                </div>
              ),
            },
          ]}
          scroll={{ y: 500 }}
          dataSource={selectedRows}
          pagination={false}
          className="right-table"
          bordered
        />
      </Box>
    </div>
  );
});

function getPagingFromRequest(paging: any) {
  if (!paging) {
    return { ...DEFAULT_PAGING };
  }
  return {
    current: paging.pageNum,
    pageSize: DEFAULT_PAGING.pageSize,
    total: paging.total,
  };
}

function DelIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9497 3.05025C15.6834 5.78392 15.6834 10.2161 12.9497 12.9497C10.2161 15.6834 5.78392 15.6834 3.05025 12.9497C0.316583 10.2161 0.316583 5.78392 3.05025 3.05025C5.78392 0.316583 10.2161 0.316583 12.9497 3.05025ZM10.1977 4.91536L8.04103 7.07202L5.88435 4.91536L4.96511 5.8346L7.12173 7.99122L4.96511 10.148L5.88435 11.0672L8.04103 8.91052L10.1977 11.0672L11.1169 10.148L8.96023 7.99122L11.1169 5.8346L10.1977 4.91536Z"
        fill="rgb(193, 203, 214)"
        fillOpacity="0.65"
      />
    </svg>
  );
}
