(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("UdeskBoot"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define("TangoAntd", ["React", "UdeskBoot", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["TangoAntd"] = factory(require("React"), require("UdeskBoot"), require("ReactDOM"));
	else
		root["TangoAntd"] = factory(root["React"], root["UdeskBoot"], root["ReactDOM"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__1024__, __WEBPACK_EXTERNAL_MODULE__9463__, __WEBPACK_EXTERNAL_MODULE__314__) => {
return 