
export enum DataTypes {
    document_item = 'documentItem',
    knowledge_item = 'knowledgeItem',
    material_repository = 'material_repository',
    knowledge_template_item = 'knowledgeTemplateItem',
    use_full_expression = 'useFullExpression',
    use_full_link = 'useFullLink',
}

const dataTypesformatters = {
    [DataTypes.knowledge_item]: (item: any) => ({
        name: getItemValueProperty(item, 'name'),
        content: getItemValueProperty(item, 'content'),
        key: `${DataTypes.knowledge_item}-${item.value.id}`,
        type: DataTypes.knowledge_item,
        templateId: item.value.templateId || 0,
        knowledgeBaseId: item.value.knowledgeBaseId,
        id: item.value.id,
    }),
    [DataTypes.document_item]: (item: any) => ({
        name: getItemValueProperty(item, 'title'),
        content: getItemValueProperty(item, 'content'),
        key: `${DataTypes.document_item}-${item.value.id}`,
        type: DataTypes.document_item,
        templateId: item.value.templateId || 0,
        knowledgeBaseId: item.value.knowledgeBaseId,
        id: item.value.id,
    }),
    [DataTypes.material_repository]: (item: any) => ({
        name: getItemValueProperty(item, 'name'),
        content: getItemValueProperty(item, 'attachmentContent'),
        key: `${DataTypes.material_repository}-${item.value.id}`,
        type: DataTypes.material_repository,
        templateId: item.value.templateId || 0,
        knowledgeBaseId: item.value.knowledgeBaseId,
        id: item.value.id,
        previewUrl: item.value.previewUrl,
        url: item.value.url,
    }),
    [DataTypes.knowledge_template_item]: (item: any) => ({
        name: getItemValueProperty(item, 'name'),
        content: getValue(item),
        key: `${DataTypes.knowledge_template_item}-${item.value.id}`,
        type: DataTypes.knowledge_template_item,
        templateId: item.value.templateId || 0,
        knowledgeBaseId: item.value.knowledgeBaseId,
        id: item.value.id,
    }),
    [DataTypes.use_full_expression]: (item: any) => ({
        name: getItemValueProperty(item, 'content'),
        content: getItemValueProperty(item, 'content'),
        key: `${DataTypes.use_full_expression}-${item.value.id}`,
        type: DataTypes.use_full_expression,
        templateId: item.value.templateId || 0,
        knowledgeBaseId: item.value.knowledgeBaseId,
        id: item.value.id,
    }),
    [DataTypes.use_full_link]: (item: any) => ({
        name: getItemValueProperty(item, 'name'),
        content: getItemValueProperty(item, 'url'),
        key: `${DataTypes.use_full_link}-${item.value.id}`,
        type: DataTypes.use_full_link,
        templateId: item.value.templateId || 0,
        knowledgeBaseId: item.value.knowledgeBaseId,
        id: item.value.id,
    }),
}

export function formatSearchResult(originData: any[]) {
    return originData?.map((item: any) => Object.assign({
        __manual: true, // 标识手动选择
    }, dataTypesformatters[item.type]?.(item))) ?? []
}

function getItemValueProperty(item: any, path: string) {
    return getValue(item)[path] ?? '-';
}

function getValue(item: any) {
    return item.value || {}
}