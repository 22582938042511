import { __rest } from "tslib";
import React from 'react';
import { coral, forwardRef } from 'coral-system';
const Div = coral('div', undefined, {
    prefix: '--music',
});
export const Box = forwardRef((_a, ref) => {
    var { isRender = true } = _a, props = __rest(_a, ["isRender"]);
    if (isRender === false)
        return React.createElement(React.Fragment, null);
    return React.createElement(Div, Object.assign({ ref: ref }, props));
});
