import { css } from 'styled-components';
import { coral } from 'coral-system';
const truncatedStyle = css `
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const textClampStyle = css `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.$lineClamp};
`;
const textStyle = css `
  ${(props) => props.$lineClamp > 0 && textClampStyle};
  ${(props) => props.$truncated && truncatedStyle};
`;
export const Text = coral('span', textStyle, {
    prefix: '--music',
    attrs: (props) => ({
        textAlign: props.align,
        $truncated: props.isTruncated,
        $lineClamp: props.lineClamp,
    }),
});
