import { defineServices as _defineServices, history } from 'udesk-portal-boot';
import type { AxiosRequestConfig } from 'axios';
import { message } from 'antd';

interface RequestConfig extends AxiosRequestConfig {
  /**
   * 响应格式化函数
   * @param data 响应数据
   * @returns 返回格式化后的数据
   */
  formatter?: (data: any) => any;
  /**
   * 路径变量，例如 `/users/:id`，其中 id 为路径变量
   */
  pathVariables?: Record<string, any>;
}

interface IDefineServicesBaseConfig extends RequestConfig {
  namespace?: string;
  onError?: (e: any) => any;
}

export const search2json = (
  query: string,
  typeConfig: {
    [key: string]: 'int' | 'float' | 'boolean';
  } = {},
) => {
  const searchParams = new URLSearchParams(query);
  const res: Record<string, string> = {};
  const format = (key: string, value: string) => {
    const type = typeConfig[key];
    if (type) {
      if (type === 'int') {
        return parseInt(value, 10);
      }
      if (type === 'float') {
        return parseFloat(value);
      }
      if (type === 'boolean') {
        const enums = {
          '0': false,
          '-1': false,
          false: false,
          '1': true,
          true: true,
        };
        return enums[value];
      }
    }
    return value;
  };

  for (const [key, value] of searchParams.entries()) {
    res[key] = format(key, value);
  }
  return res;
};

export const json2search = (params: any) => {
  const list: string[] = [];
  if (params) {
    for (const key in params) {
      const value = params[key];
      if (!(value === null || value === undefined)) {
        let tvalue = typeof value === 'string' ? value.replace(/&/g, '%26') : value;
        list.push(`${key}=${tvalue}`);
      }
    }
  }
  console.log('list==', list);
  return list.join('&');
};

export const mergeSearchParams = (params: any) => {
  return json2search({
    ...(getSearchParams() || {}),
    ...params,
  });
};

export function getSearchParams(
  typeConfig: {
    [key: string]: 'int' | 'float' | 'boolean';
  } = {},
) {
  return search2json(history.current?.location?.search, typeConfig);
}

export function getPath(path: string) {
  return concatUrl(getOrigin(), path);
}

export function getOrigin() {
  // 兼容在编辑器内编辑的场景，尝试获取iframe外层的origin
  return window?.parent?.location?.origin || window.location.origin;
}

export function defineServices(
  configs: Record<string, RequestConfig>,
  baseConfig?: IDefineServicesBaseConfig,
) {
  return _defineServices(configs as any, { onError, ...baseConfig } as any);
}

const FROM = 'kcs';

export function onError(e: any) {
  (window?.parent as any)?.console.log(e);

  if (e.response.status === 401) {
    const { kpAuth } = udesk.services;
    kpAuth.getUserCenterUrl().then((ret: any) => {
      const searchParams = `?from=${FROM}&sourceUrl=${window.location.href}`;
      window.location.replace(`${ret}page/login${searchParams}`);
    });
  }
  // if (e.response.status === 400) {
  //     message.error(e.response.data.message || 'error');
  // }
  throw e;
}

function concatUrl(origin: string, path: string) {
  return `${origin}${startsWithSlash(path) ? `/kcs${path}` : `/kcs/${path}`}`;
}
function startsWithSlash(string: string) {
  return string.startsWith('/');
}
