import React, { useMemo, useState, useRef } from 'react';
import { RadioGroup } from '@headlessui/react';
import { css } from 'coral-system';
import { Box } from '@music163/foundation';
import cx from 'classnames';
import { DataSelect } from './dataSelect';
import { Button, Modal } from 'antd';

const style = css`
  padding: 12px 10px;
  background: #fafafa;
  border-radius: 4px;
  margin-bottom: 15px;
  display: flex;
  cursor: pointer;
  width: 100%;
  overflow-x: hidden;

  &.checked {
    background: #fff;
    outline: 1px solid #008dfc;
  }

  .radio-fake {
    border-radius: 100%;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #ccc;
    transition: background-color 0.3s ease;
    place-self: flex-start;
    margin-top: 2px;
    position: relative;
    margin-right: 16px;

    .radio-icon {
      width: 16px;
      height: 16px;
    }

    &.checked {
      background-color: #008dfc;
      border: 1px solid #008dfc;
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -4px 0 0 -4px;
        width: 8px;
        height: 8px;
        background-color: #fff;
        border-radius: 100%;
      }
    }
  }
  .list-picker-text {
    color: #999;
    font-size: 12px;
    height: 25px;
    padding: 0 5px;
    margin-top: 8px;
    width: 240px;
    min-width: 0;
  }
  .list-group {
    color: #999;
    font-size: 12px;

    .list-picker-item {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 18px;
      border-radius: 2px;
      padding: 4px 5px;
      transition: all 0.2s linear;
      min-width: 0;
      &:hover {
        background-color: #f5f7fa;
      }
      .list-item-inner {
        display: flex;
        align-items: center;
        flex: 1;
        overflow: hidden;

        > div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:hover {
          color: #008dfc;
        }
      }
    }
  }
`;

const getOptions = (scene: number) => {
  const newKnowledges = { label: '最新发布', value: '{udesk.stores?.kpHomepage?.newKnowledges}' };
  const notices = { label: '公告', value: '{udesk.stores?.kpHomepage?.notices}' };
  const recommendKnowledges = {
    label: '推荐知识',
    value: '{udesk.stores?.kpHomepage?.recommendKnowledges}',
  };
  const userRank = { label: '积分排行', value: '{udesk.stores?.kpHomepage?.userRank}' };
  const hotKnowledge = { label: '最热知识', value: '{udesk.stores?.kpHomepage?.hotKnowledge}' };
  const constBtn = { label: '手动添加文档', value: 'const', showConstDataBtn: true };

  if (scene) {
    // 对外场景
    return [newKnowledges, recommendKnowledges, hotKnowledge, constBtn];
  }
  // 对内场景
  return [newKnowledges, notices, recommendKnowledges, userRank, hotKnowledge, constBtn];
};

function Component({ value, onChange, portal, ...rest }: any) {
  const refDataSelect = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const _onChange = (newValue: any) => {
    if (newValue === 'const') {
      onChange?.([]);
      return;
    }
    onChange?.(newValue);
  };
  const _value = useMemo(() => {
    if (isConstData(value)) {
      return 'const';
    }
    return value;
  }, [value]);

  return (
    <>
      <RadioGroup value={_value} onChange={_onChange} style={{ marginTop: 8 }}>
        {getOptions(portal.scene).map((option) => {
          return (
            <RadioGroup.Option value={option.value}>
              {({ checked }) => (
                <Box
                  css={style}
                  className={cx({
                    checked,
                  })}
                >
                  <Box
                    className={cx({
                      'radio-fake': true,
                      checked,
                    })}
                  >
                    <div className="radio-icon" />
                  </Box>
                  <Box flex={1} overflowX="hidden">
                    {option.label}
                    {isConstData(value) && option.showConstDataBtn && (
                      <>
                        {Array.isArray(value) && (
                          <div>
                            <div className="list-picker-text">已选择以下文档：</div>
                            <div className="list-group">
                              {value.map((i) => {
                                return (
                                  <div className="list-picker-item">
                                    <Icon />
                                    <div className="list-item-inner" title={i.name}>
                                      <div>{i.name}</div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        <div>
                          <Box
                            onClick={() => {
                              // _onChange([{ title: 'aaaa' }, { title: 'bbb' }]);
                              setIsOpen(true);
                            }}
                            style={{
                              marginTop: 8,
                              color: '#008dfc',
                            }}
                          >
                            选择文档
                          </Box>
                        </div>
                      </>
                    )}
                  </Box>
                </Box>
              )}
            </RadioGroup.Option>
          );
        })}
      </RadioGroup>
      <Modal
        width={'70vw'}
        title="请选择文档"
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={[
          <Button
            onClick={() => {
              onChange(refDataSelect.current.selectedRows);
              setIsOpen(false);
            }}
            type="primary"
          >
            确定
          </Button>,
          <Button onClick={() => setIsOpen(false)}>取消</Button>,
        ]}
      >
        <DataSelect
          portalId={portal.id}
          defaultValue={value}
          ref={refDataSelect}
        />
      </Modal>
    </>
  );
}

export default {
  name: 'dataSourceSetter',
  component: (portal: any) => (props: any) => {
    return <Component {...props} portal={portal} />;
  },
};

function isConstData(value: string | any[] | undefined) {
  // if (typeof value === 'string') return !value.startsWith('{udesk.stores');

  return Array.isArray(value);
}

function Icon() {
  return (
    <svg
      data-v-05593cef=""
      viewBox="0 0 16 16"
      style={{ fill: 'rgb(193, 203, 214)', width: 16, marginRight: 8 }}
    >
      <use data-v-05593cef="" xlinkHref="#venom-icon-drag-list-move" />{' '}
      <g data-v-05593cef="">
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          className="design-iconfont"
          id="venom-icon-drag-list-move"
        >
          <path d="M14 11v1.5H2V11h12zm0-3.5V9H2V7.5h12zM14 4v1.5H2V4h12z" fillRule="evenodd" />
        </symbol>
      </g>
    </svg>
  );
}
