import { __rest } from "tslib";
import React from 'react';
import { forwardRef } from 'coral-system';
import { Box } from './box';
export const Columns = forwardRef((props, ref) => {
    const { columns = 12 } = props, rest = __rest(props, ["columns"]);
    const templateColumns = getTemplateColumns(columns);
    return (
    // @ts-ignore
    React.createElement(Box, Object.assign({ ref: ref, display: "grid", gridGap: "xxl", gridTemplateColumns: templateColumns }, rest)));
});
export const Column = forwardRef((props, ref) => {
    const { colSpan, colStart, colEnd, rowSpan, rowStart, rowEnd } = props, rest = __rest(props, ["colSpan", "colStart", "colEnd", "rowSpan", "rowStart", "rowEnd"]);
    return (React.createElement(Box, Object.assign({ ref: ref, gridColumn: getSpan(colSpan), gridColumnStart: colStart, gridColumnEnd: colEnd, gridRowStart: rowStart, gridRowEnd: rowEnd, gridRowSpan: rowSpan }, rest)));
});
function getTemplateColumns(columns) {
    return `repeat(${columns}, 1fr)`;
}
function getSpan(span) {
    return span === 'auto' ? 'auto' : `span ${span}/span ${span}`;
}
